
  import {defineComponent, ref, reactive, inject} from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import qs from "qs";
import router from "@/router";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import AddressList from "@/components/address/AddressListOfNei.vue";
import ChooseAddressCard from "@/components/common/ChooseAddressCard.vue";
  import {useRoute} from "vue-router";
export default defineComponent({
  name: "updateAddressOfOrder",
  components: {
    AddressList,
    Loading,
    ChooseAddressCard
  },
  setup() {
    const listAddress = ref<[] | null>(null);
    const checked1 = ref<string[]>([]);
    const loading = ref(false);
    const remarkValue = ref();
    const userInfo = inject("userInfo") as any;

    const route = useRoute();
    const ono = route.query.ono;
    const postId = route.query.postId;
    // console.log("ono",ono)
    // console.log("postId",postId)

    function onSubmit() {
      const addressIdOfChecked = checked1.value[0];
      if (!checked1.value.length) {
        Toast({
          type: "warning",
          title: "请选择收货地址",
        });
        return false;
      }
      if(postId == addressIdOfChecked){
        Toast({
          type: "warning",
          title: "当前选择的地址与原地址相同，无需进行修改操作",
        });
        return false;
      }
      axios
        .post(
          "/M/Server/updateAddressOfOrder",
          qs.stringify({
            addressId: addressIdOfChecked,
            ono:ono,
          })
        )
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            router.push({
              name: "NeiOrderDetail",
              query: {
                id: ono,
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
        });
    }

    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };

    function handleDel(id: string) {
      Modal({
        title: "温馨提示",
        content: "确认删除?",
        onConfirm: () => {
          const data = qs.stringify({
            id
          });
          axios.post("/M/Server/DoDelAddress", data).then(res => {
            console.log(res)
            Toast({
              type: "success",
              title: "删除成功！",
            });
            // getList()
            window.location.reload()
          }).catch(err => {
            console.log(err)
          })
        },
        onCancel: () => {
          console.log('取消')
        },
      });
    }
    return {
      listAddress,
      checked1,
      onSubmit,
      loadOption,
      loading,
      handleDel,
      remarkValue,
      ono,
      postId
    };
  },
});
